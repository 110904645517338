<template>
  <div class='page-box'>
        <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent>
        <a-form-model-item label="分销商">
          <a-select
            v-model="params.partnerShopId"
            placeholder="请选择"
            :options='options'
            style="min-width:200px;"
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div style='margin-bottom: 18px;'>
      <a-button type='primary' v-pms="'add'" :loading="getPostImgLoading" @click='getPostImg'>发展分销商</a-button>
    </div>
    <a-table
      bordered
      :columns="columns"
      :pagination='pagination'
      :dataSource="tableData"
      :rowKey="record => record.partnerShopId"
      :loading="tableLoading"
      @change="handleTableChange">
      <template slot="name"  slot-scope="text,record">
        <div class='table-name-box'>
          <base-img
            class="goods-img"
            :src="record.logo"
            width="60"
            height="60"
          />
          <div>
            <p class='table-name'>{{record.name}}</p>
            <p :style="{color:shopAuthStates.find(item=>item.value === record.authState).color}">{{shopAuthStates.find(item=>item.value === record.authState).name}}</p>
          </div>
        </div>
      </template>
      <template slot="linkPhone" slot-scope="text,record">
        <div>{{record.linkMan}}</div>
        <div>{{record.linkPhone}}</div>
      </template>
      <template slot="afterServiceTel" slot-scope="text,record">
        <div>
          <p v-for='(item,index) in record.afterServiceTel' :key='index'>
            {{item}}
          </p>
        </div>
      </template>
      <div class="table-operations" slot="operation" slot-scope="text, record">
        <a-button type="link" v-pms="'setLevel'" @click="setLevelFn(record.shopFollowId)">设置等级</a-button>
        <a-popconfirm
          placement="topRight"
          title="确定要解除绑定？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="()=>confirmDelete(record)"
        >
          <a-button type="link" v-pms="'unBind'">解除关系</a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <a-modal class='post-img-modal' v-model="postModalShow" :closable='false' :width="360" :footer="null">
      <div class='post-img-box'>
        <img v-if="postImg" :src="postImg" alt="" class="post-img">
        <p v-else>二维码生成失败</p>
      </div>
    </a-modal>
    <a-modal v-model="setLevelModalSHow"  :confirm-loading='levelDataSubmitLoading' title="设置等级" @ok="submitLevel">
      <a-spin :spinning="levelModalLoading">
        <a-form-model
          ref="levelForm"
          :model="levelData"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="rules">
          <a-form-model-item label="当前等级">
            <span>{{levelData.currentLevel}}</span>
          </a-form-model-item>
          <a-form-model-item label="设置等级" prop='shopLevel'>
            <a-radio-group v-model="levelData.shopLevel" @change='changeRatio'>
              <a-radio :value="1">
                1级
              </a-radio>
              <a-radio :value="2">
                2级
              </a-radio>
              <a-radio :value="3">
                3级
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="等级折扣率" prop='levelDiscountRatio'>
            <a-input addonAfter="%" style='width:120px' placeholder="levelDiscountRatio" v-model='levelData.levelDiscountRatio'>
            </a-input>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import NP from 'number-precision'
import { toRefs, reactive, onMounted } from '@vue/composition-api'
import { shopAuthStates } from '@/utils/constants'
import { partner, common } from '@/api'
import { SHOPID, TOKEN } from '@/constants'
import { getSession } from '@/utils/session'
import axios from 'axios'
const checkRatio = (rule, value, callback) => {
  if (value) {
    const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
    if (!reg.test(value)) {
      return callback(new Error('整数或最多两位小数'))
    } else if (value > 100) {
      return callback(new Error('不能超过100'))
    } else if (value <= 0) {
      return callback(new Error('必须大于0'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}
export default {
  name: 'PageDistributor',
  setup (props, { root }) {
    const labelCol = { span: 6 }
    const wrapperCol = { span: 16 }
    const rules = {
      shopLevel: [
        { required: true, message: '请选择等级', trigger: 'change' }
      ],
      levelDiscountRatio: [
        { required: true, message: '请设置等级折扣率', trigger: 'change' },
        { validator: checkRatio, trigger: 'change' }
      ]
    }
    const state = reactive({
      params: {
        type: 1,
        shopId: getSession(SHOPID)
      },
      levelData: {},
      formRef: null,
      tableLoading: false,
      setLevelModalSHow: false,
      options: [],
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      postImg: '',
      postModalShow: false,
      levelModalLoading: false,
      getPostImgLoading: false,
      levelDataSubmitLoading: false
    })
    const columns = [
      {
        title: '分销商名称',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' }
      },
      {
        title: '联系方式',
        dataIndex: 'linkPhone',
        scopedSlots: { customRender: 'linkPhone' }
      },
      {
        title: '售后电话',
        dataIndex: 'afterServiceTel',
        scopedSlots: { customRender: 'afterServiceTel' }
      },
      {
        title: '商品总数量',
        dataIndex: 'productNum',
        customRender: x => {
          return x || 0
        }
      },
      {
        title: '采集我的商品数量',
        dataIndex: 'collectProductNum',
        customRender: x => {
          return x || 0
        }
      },
      {
        title: '当前状态',
        dataIndex: 'state',
        customRender: x => {
          return '有效'
        }
      },
      {
        title: '生效时间',
        dataIndex: 'createTime'
      },
      {
        title: '操作',
        width: 170,
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' }
      }
    ]

    function handleTableChange (pagination) {
      state.pagination = pagination
      getTableList()
    }

    function handleSearch () {
      state.pagination.current = 1
      getTableList()
    }

    function handleReset () {
      state.pagination.current = 1
      state.params = {
        type: 1,
        shopId: getSession(SHOPID)
      }
      handleSearch()
    }
    async function getMySuppler () {
      let { code, msg, data } = await common.getListPartner(1)
      if (code === '00000') {
        state.options = data.map(item => {
          return {
            label: item.name,
            value: item.shopId
          }
        })
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function getTableList () {
      state.tableLoading = true
      let { code, data, msg, page } = await partner.getMySupplerPage({
        ...state.params,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
      state.tableLoading = false
      if (code === '00000') {
        state.tableData = data.map(item => {
          if (item.authState === undefined) {
            item.authState = 0
          }
          if (item.afterServiceTel) {
            item.afterServiceTel = item.afterServiceTel.split(',')
          }
          return item
        })
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function confirmDelete (record) {
      let { code, msg } = await partner.unBindRelation({
        deleteFlag: true,
        shopFollowId: record.shopFollowId,
        upShopId: record.shopId,
        downShopId: record.partnerShopId
      })
      if (code === '00000') {
        root.$message.success('解除绑定成功')
        getTableList()
      } else {
        root.$message.error(msg || '解除绑定失败')
      }
    }
    async function getPostImg () {
      state.getPostImgLoading = true
      axios({
        url: '/shopFollow/createActivityQrCode',
        baseURL: process.env.VUE_APP_API,
        headers: { 'Authorization': getSession(TOKEN) },
        params: {
          shopId: getSession(SHOPID),
          followType: 2
        },
        responseType: 'arraybuffer'
      }).then((response) => {
        state.getPostImgLoading = false
        // 将从后台获取的图片流进行转换
        return 'data:image/png;base64,' + btoa(
          new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
        )
      }).then((data) => {
        if (data) {
          showPostImgModal(data)
        }
      }).catch(() => {
        root.$message.error('生成二维码失败')
      })
    }
    function showPostImgModal (data) {
      state.postImg = data
      state.postModalShow = true
    }
    async function setLevelFn (id) {
      state.setLevelModalSHow = true
      state.levelModalLoading = true
      let { code, data, msg } = await partner.getLevelData({
        shopFollowId: id
      })
      state.levelModalLoading = false
      if (code === '00000') {
        data.levelDiscountRatio = NP.times(data.levelDiscountRatio, 100)
        state.levelData = data
        state.levelData.currentLevel = data.shopLevel
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function submitLevel () {
      state.levelDataSubmitLoading = true
      let { code, msg } = await partner.unBindRelation({
        shopFollowId: state.levelData.shopFollowId,
        shopLevel: state.levelData.shopLevel,
        levelDiscountRatio: NP.divide(state.levelData.levelDiscountRatio, 100)
      })
      state.levelDataSubmitLoading = false
      if (code === '00000') {
        state.setLevelModalSHow = false
        root.$message.success('设置成功')
        getTableList()
      } else {
        root.$message.error(msg || '设置失败')
      }
    }
    function changeRatio (e) {
      root.$set(state.levelData, 'levelDiscountRatio', 0)
    }
    onMounted(() => {
      getMySuppler()
      getTableList()
    })
    return {
      labelCol,
      wrapperCol,
      rules,
      shopAuthStates,
      baseUrl: process.env.VUE_APP_IMG,
      columns,
      ...toRefs(state),
      handleReset,
      handleSearch,
      handleTableChange,
      getMySuppler,
      getTableList,
      confirmDelete,
      getPostImg,
      showPostImgModal,
      setLevelFn,
      submitLevel,
      changeRatio
    }
  }
}
</script>
<style lang="less" scoped>
  .table-name-box {
    display: flex;
    align-items: center;
    .goods-img {
      margin-right:8px;
      flex-shrink: 0;
    }
    .table-name {
      margin-bottom: 8px;
    }
  }
  .post-img-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .post-img {
    width: 100%;
    object-fit: contain;
  }
.post-img-modal  ::v-deep .ant-modal-body {
  padding: 0
}
</style>
